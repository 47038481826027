<template>
  <div class="feedback_box">
    <div class="feedback_content row">
      <div class="nav_box my_card">
        <div class="nav_item fs16  hand" @click="onNavChange(item)"
             :class="{'is_selectNav': item.componentName === componentName}"
             v-for="item in childrenComopnents" :key="item.id">
          <span>{{item.navName}}</span>
        </div>
      </div>

      <div class="component_box ">
        <component :is="componentName"></component>
      </div>
    </div>
  </div>
</template>
<script>
import SubmitFeedback from './components/submitFeedback.vue'
// import FeedbackNotice from './components/feedbackNotice.vue'
export default {
  components: {
    SubmitFeedback
    // FeedbackNotice
  },
  data () {
    return {
      childrenComopnents: [
        {
          componentName: 'SubmitFeedback',
          navName: '意见反馈',
          id: 1
        }
        // {
        //   componentName: 'FeedbackNotice',
        //   navName: '反馈通知',
        //   id: 2
        // }
      ],
      componentName: 'SubmitFeedback'
    }
  },
  methods: {
    onNavChange (item) {
      this.componentName = item.componentName
    }
  }
}
</script>
<style lang="less" scoped>
.feedback_box {
  width: 100%;
  min-height: 500px;
  background-color: #f5f7fb;
  .feedback_content {
    display: grid;
    grid-template-columns: 177px 1fr;
    grid-column-gap: 20px;
    align-items: start;
    min-height: calc(100vh - 252px);
    padding-bottom: 30px;
  }
  .nav_box {
    .nav_item {
      position: relative;
      text-align: center;
      padding: 15px 30px;
    }
    .is_selectNav {
      color: #2b72e0;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 40px;
        width: 5px;
        height: 5px;
        border: 1px solid #2b72e0;
        border-radius: 50%;
        margin: auto 0;
      }
    }
  }
  .component_box {
    min-height: 500px;
  }
}
</style>
