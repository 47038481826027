<template>
  <div class="submitFeedback_box my_card">
    <h2>意见反馈</h2>
    <h4 class="title">反馈类型: </h4>
    <div class="type_list_box">
      <div @click="onSelectFeedbackType(item)" class="type_item fs14 my_btn_slide  hand"
           v-for="item in feedbackTypes" :class="{'is_select_type':form.type === item.typeName}"
           :key="item.typeName">
        {{item.typeName}}
      </div>
    </div>
    <h4 class="title">意见反馈: </h4>
    <el-input type="textarea" :autosize="{ minRows: 8, maxRows: 8}" placeholder="请输入内容"
              v-model="form.content">
    </el-input>
    <div class="submitBtn_box">
      <span class="submit_btn hand" @click="submitFeedback">提交</span>
    </div>
  </div>
</template>
<script>
import { submitSystemFeedback } from '@/api/feedback'
import { confirm, getCurrentBrowser, getOs } from '@/utils/index.js'
export default {
  data () {
    return {
      feedbackTypes: [
        {
          typeName: '产品使用'
        },
        {
          typeName: '软件错误'
        },
        {
          typeName: '医学内容'
        },
        {
          typeName: '其他问题'
        }
      ],
      form: {
        type: '产品使用',
        content: ''
      }
    }
  },
  methods: {
    submitFeedback () {
      if (!this.form.content) return this.$myMessage.warning('请输入您的意见反馈')
      confirm({
        warning: '是否确认提交?',
        success: async () => {
          await submitSystemFeedback({
            feedbackType: this.form.type,
            feedbackContent: this.form.content,
            deviceName: getCurrentBrowser(),
            systemVersion: 'PC',
            terminalType: getOs(),
            appVersion: 'PC'
          })
          this.form = this.$options.data().form
          this.$message.success('感谢您的反馈，我们会尽快处理')
        },
        catch: (err) => {
          console.log(err)
          if (err !== 'cancel') {
            this.$message.error('反馈失败')
          }
        }
      })
    },
    onSelectFeedbackType (item) {
      this.form.type = item.typeName
    }
  }

}
</script>
<style lang="less" scoped>
.submitFeedback_box {
  padding: 50px 40px;
  h2 {
    text-align: center;
    margin-bottom: 40px;
  }
  .title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  .type_list_box {
    display: grid;
    grid-template-columns: repeat(4, 76px);
    grid-column-gap: 16px;
    margin-bottom: 30px;
    .type_item {
      height: 28px;
      line-height: 28px;
      text-align: center;
      color: #666;
      border: 1px solid #666666;
    }
    .is_select_type {
      border-color: #4b639f;
      color: #4b639f;
    }
  }
  /deep/ .el-textarea__inner {
    background-color: #f5f7fb;
    resize: none;
    &:focus {
      border-color: #c0c4cc;
    }
  }
  .submitBtn_box {
    margin-top: 30px;
    text-align: end;
    .submit_btn {
      display: inline-block;
      width: 100px;
      height: 34px;
      line-height: 34px;
      text-align: center;
      background-color: #4b639f;
      color: #fff;
    }
  }
}
</style>
